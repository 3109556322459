export const disciplineAllegations = [
	{
		vb: 'Mapleview Developments Ltd.',
		link: 'https://obd.hcraontario.ca/profile/B46303',
		referralDate: 'November 10, 2023',
		nextSteps: 'Proceedings stayed due to Receivership Order dated March 21, 2024'
	},
	{
		vb: 'Briarwood (Angus) Ltd.',
		link: 'https://obd.hcraontario.ca/profile/B48253',
		referralDate: 'April 11, 2024',
		nextSteps: 'Licensee Motion: January 2025'
	},
	{
		vb: 'Briarwood Estates (Sutton) Ltd.',
		link: 'https://obd.hcraontario.ca/profile/B43292',
		referralDate: 'April 11, 2024',
		nextSteps: 'Licensee Motion: January 2025'
	},
	{
		vb: '863195 Ontario Limited',
		link: 'https://obd.hcraontario.ca/profile/B47464',
		referralDate: 'April 11, 2024',
		nextSteps: 'Licensee Motion: January 2025'
	},
	{
		vb: '2401886 Ontario Limited',
		link: 'https://obd.hcraontario.ca/profile/B45312',
		referralDate: 'April 11, 2024',
		nextSteps: 'Licensee Motion: January 2025'
	},
	{
		vb: 'Starlish Home Corp.',
		link: 'https://obd.hcraontario.ca/profile/B46885',
		referralDate: 'October 31, 2024',
		nextSteps: 'Case Conference: to be scheduled'
	},
	{
		vb: 'Dormer Bond Inc.',
		link: 'https://obd.hcraontario.ca/profile/B47898',
		referralDate: 'November 28, 2024',
		nextSteps: 'Case Conference: to be scheduled'
	}
];

export const disciplineDecisions = [
	{
		vb: '1332741 Ontario Inc.',
		link: 'https://obd.hcraontario.ca/profile/B28375',
		decisionDate: 'November 20, 2022',
		decisionTxt:
			'NHCLA - Code of Ethics - s. 2 Licencee to ensure compliance; s.9 Intimidation coercion etc.; and s. 16 Obstruction of complaints',
		outcomeTxt:
			'1332741 Ontario Inc. o/a Patten Homes 2000 is ordered to pay a fine of $10,000 and to arrange for its employees to complete the following education courses: Customer Service and Tarion Requirements, Legal Issues in Housing'
	},
	{
		vb: 'LJM Developments (Stoney Creek) Inc.',
		link: 'https://obd.hcraontario.ca/profile/B46112',
		decisionDate: 'October 17, 2024',
		decisionTxt:
			'LJM Developments (Stoney Creek) Inc. has failed to comply with Section 3 and 6 of the Code of Ethics, being Ontario regulation 245/21 made under the New Home Construction Licensing Act, 2017.',
		outcomeTxt:
			'LJM Developments (Stoney Creek) Inc. is ordered to pay a fine of $60,000 to the HCRA and arrange for Liaquat Mian and Muhammad Ahsan to complete and pass educational courses within 60 days of the date of the Order.'
	}
];
